import React, {Component} from 'react'
import {update_user, token_check} from "../actions/user_actions";
import {connect} from "react-redux";


class Token extends Component{
    componentDidMount() {
        console.log(this.props.match.params.token);
        const token = this.props.match.params.token;
        this.props.onTokenCheck(token);
        this.props.history.push('/launcher')
    }

    render() {
        return (
            <div>

            </div>
        );
    }
}


const mapStateToProps = state => ({
    user: state.user
});

const mapActionToProps = ({
    onUpdateUser: update_user,
    onTokenCheck: token_check
});

export default connect(mapStateToProps, mapActionToProps)(Token);