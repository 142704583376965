import React, {Component} from "react"
import {api_request, update_user} from "../actions/user_actions";
import {connect} from "react-redux";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import logo from "../assesets/Seal_of_the_Los_Angeles_Unified_School_District.svg.png"
import Link from '@material-ui/core/Link';
import Grid from "@material-ui/core/Grid/Grid";

const styles = {
    card: {
        display: 'flex',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 151,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
    },
    playIcon: {
        height: 38,
        width: 38,
    },
};


class Launcher extends Component {
    render() {
        const classes = styles;
        return (
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: '100vh' }}
            >
                <Grid>
                    <Link  href={`https://staging.lasr.coredistricts.org/token/${this.props.user.token}`} >
                        <Card className={classes.card} style={{
                            backgroundColor:"rgba(213, 241, 255, 0.58)",
                            border: '3px #005C8B solid',
                            borderRadius: '25px'}}>
                            <div className={classes.details}>
                                <CardContent className={classes.content}>
                                    <img  style={{
                                            height: '10em',
                                            float: 'left',
                                            padding: '1em'}}
                                          src={logo}
                                    alt={"LAUSD  logo"}/>
                                    <Typography alignItems="center"
                                                justify="center"
                                                component="h5"
                                                variant="h5"
                                                style={{paddingTop: '13%',
                                                   paddingLeft: '37%',
                                                   color: '#005C8B',
                                                   fontWeight: 'bold'}}
                                    >
                                        Click to preview the School Information<sup>+</sup> site
                                    </Typography>
                                </CardContent>
                            </div>
                        </Card>
                    </Link>
                    <Link href={`https://dashboard.coredistricts.org/token/${this.props.user.token}/redirect=/dashboard`} >
                        <Card className={classes.card} style={{
                            marginTop: '5em',
                            backgroundColor:"#E1ECE6",
                            border: '1px black solid'}}>
                            <div className={classes.details}>
                                <Typography alignItems="center"
                                            justify="center"
                                            component="h5"
                                            variant="h5"
                                            style={{textAlign: 'center', fontSize: '12pt'}}>
                                    Log onto the CORE Districts Dashboard
                                </Typography>
                            </div>
                        </Card>
                    </Link>
                </Grid>
            </Grid>
        );
    }
}
//
const mapStateToProps = state => ({
    user: state.user
});

const mapActionToProps = ({
    onUpdateUser: update_user,
    onApiRequest: api_request
});

export default connect(mapStateToProps, mapActionToProps)(Launcher);
