import { UPDATE_USER,SHOW_ERROR, TOKEN_CHECK } from "../actions/user_actions";

export function userReducer(state = {token_verified:false}, action) {
    if (action.type === UPDATE_USER){
        return action.payload.new_user
    }
    if(action.type === SHOW_ERROR) {
        return action.payload.new_user
    }
    if(action.type === TOKEN_CHECK) {
        return action.payload.token
    }
    return state;
}
