import React , {Component}from 'react';
import { connect } from 'react-redux'
import { update_user, api_request } from './actions/user_actions'
import {BrowserRouter as Router, Route} from 'react-router-dom'
import Token from "./auth/Token"
import Home from "./pages/home"
import Container from '@material-ui/core/Container';
import Launcher from "./pages/launcher"


class App extends Component{
    render() {
        return (
            <Router>
                <Container maxWidth="sm">
                    {(this.props.user.token_verified) ? <Launcher /> : <Home />}
                    <Route path={'/token/:token'} render={(props) => <Token {...props} />} />
                </Container>
            </Router>
        );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapActionToProps = ({
    onUpdateUser: update_user,
    onApiRequest: api_request
});

export default connect(mapStateToProps, mapActionToProps)(App);