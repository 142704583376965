import React, {Component} from "react"
import Button from '@material-ui/core/Button';
import logo from "../assesets/Seal_of_the_Los_Angeles_Unified_School_District.svg.png"
import Grid from "@material-ui/core/Grid/Grid";

const styles = {
    image: {
        height: '21em',
        width: '21em',
    },
    buttons: {
        maxWidth: "24em",
        color: "#005C8B",
        backgroundColor:"#F5F7F8",
        border: '3px #005C8B solid',
        borderRadius: '25px',
        textAlign: 'center',
        marginRight: '-1em',
        fontWeight: 'bold',
    }
};

class Home extends Component {
    render() {
        return (
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: '100vh' }}
            >
                <Grid
                      direction="column"
                      alignItems="center"
                      justify="center">
                    <img src={logo} style={styles.image} alt={"LAUSD LOGO"}/>

                </Grid>
                <Grid
                      direction="column"
                      alignItems="center"
                      justify="center">
                <Button variant="Link"
                        href="https://core-api.edanalytics.org/auth/saml/lausd/login"
                        alignItems="center"
                        justify="center"
                        style={styles.buttons} >
                    Click here to login using your single sign on
                </Button>
                </Grid>
            </Grid>
        );
    }
}

export default Home;
