import axios from "axios"

export const UPDATE_USER = 'UPDATE_USER';
export const SHOW_ERROR = 'SHOW_ERROR';
export const TOKEN_CHECK = 'TOKEN_CHECK';

export function update_user(new_user) {
    return {
        type: UPDATE_USER,
        payload: {
            new_user: new_user
        }
    }
}

export function show_error() {
    return {
        type: SHOW_ERROR,
        payload: {new_user: {token_verified: false, token:false, user_info:false}}
    }
}

export function token_check(token) {
    return dispatch => {
        axios.get('https://core-api.edanalytics.org/auth/verify',{headers: {
                'AUTHORIZATION': token
            }})
            .then((data) => {
                let new_user = {
                    token_verified: true,
                    token: token,
                    user_info: data.data.user
                };
                dispatch(update_user(new_user))
            })
            .catch((err) => {
                console.log(err)
                dispatch(show_error())
            })
    }
}

export function api_request() {
    return dispatch => {
        axios.get('https://google.com')
            .then((data) => {
                console.log(data)
            })
            .catch((err) => {
                dispatch(show_error())
            })
    }
}